.item-card-box {
    flex-shrink: 0;
    display: block;
    height: 392px;
    width: 260px;
    /* height: 416px;
    width: 284px; */
    /* margin: 5px 10px; */
    /* background: linear-gradient(rgb(110, 104, 104), rgb(218, 218, 214)); */
    border: 1px solid #D5D6E1;
    box-sizing: border-box;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .item-card-box:hover {
    border: 2px solid #d5ac4f;

    .img-container {
      width: 256px;
      height: 256px;
    }
  }
  
  .item-card-box .img-container {
    width: 258px;
    height: 258px;
    border-radius: 16px 16px 0 0;
    -webkit-border-radius: 16px 16px 0 0;
    -moz-border-radius: 16px 16px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    position: relative;
    border-bottom: 1px solid #D5D6E1;
  }
  .item-card-box .img-container img {
    width: 100%;
    height: 100%;
    border-radius: 16px 16px 0 0;
    background: #e4e4e4;
    -webkit-border-radius: 16px 16px 0 0;
    -moz-border-radius: 16px 16px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .item-card-box .img-container .item-card-tag {
    position: absolute;
    height: 24px;
    width: auto;
    max-width: 120px;
    padding: 0 8px;
    line-height: 24px;
    background: black;
    left: 0;
    top: 24px;
    border-radius: 0px 4px 4px 0px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #292d32;
    color: #fff;
    font-size: 10px;
    gap: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .item-card-box .info-container {
    width: 100%;
    height: 132px;
    border-radius: 0 0 16px 16px;
    -webkit-border-radius: 0 0 16px 16px;
    -moz-border-radius: 0 0 16px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    padding: 16px;
    background: #FAFAFA;
  }
  .item-card-box .card-product-title {
    font-family: 'Syne', sans-serif;
    overflow: hidden;
    white-space: nowrap;
    color: #000;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    width: 100%;
  }
  
  .item-card-box .card-product-price {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 24px;
    margin-top: 8px;
  }
  .item-card-box .price {
    color: #191a23;
    font-size: 16px;
    font-weight: 700;
  }
  .item-card-box .old-price {
    font-size: 14px;
    font-weight: 500;
    color: #9fa3a6;
  }
  .item-card-box .discount {
    font-size: 12px;
    font-weight: 500;
    color: #12b985;
  }
  
  .item-card-box .card-rating-n-btn {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 8px;
  
    color: #686a79;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  
  .item-card-box .rating {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 24px;
  }
  .item-card-box .add-cart-btn {
    color: #fff;
    height: 41px;
    width: 116px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #292d32;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.4s all;
  }
  .item-card-box .add-cart-btn:hover {
    background-color: #d5ac4f;
  }
  