.home-page-info {
  padding: 4rem 7rem;
  background-size: contain;

  .text-container {
    display: flex;
    align-items: center;
    max-width: 1400px;
    margin: auto;
    .heading-container {
      width: calc(50% - 4rem);
      padding-right: 4rem;
      .heading {
        color: $color-dark-grey-1;
        font-size: 3.4rem;
        font-weight: $semi-bold-weight;
        line-height: 1.1;
        max-width: 40rem;
      }
    }
    .text-container {
      width: 50%;
      .text {
        color: $color-dark-grey-1;
        font-size: 1.8rem;
        margin: 4rem 0;
        line-height: 1.4;
        max-width: 60rem;
      }
    }
  }
  .mobile-heading {
    display: none;
  }

  .mobile-text {
    display: none;
  }

  .image-container {
    @include flex-center;
    margin: 2rem auto;
    height: 44.5rem;
    max-height: 44.5rem;
    max-width: 1400px;
  }
  img {
    width: 60%;
    height: 100%;
  }
}

@media(max-width:768px) {
  .home-page-info {
    padding: 5rem 2.2rem;
  
    .mobile-heading {
      display: block;
      color: $color-dark-grey-1;
      font-size: 3.4rem;
      font-weight: $semi-bold-weight;
      line-height: 1.1;
      max-width: 50rem;
      margin: 0 auto 5rem;
      text-align: center;
    }

    .mobile-text {
      display: block;
      color: $color-dark-grey-1;
      font-size: 1.8rem;
      margin: 4rem 0;
      line-height: 1.4;
      max-width: 50rem;
      margin: 5rem auto 0;
      text-align: center;
    }

    .text-container {
      display: none;
    }
  
    img {
      max-width: 50rem;
      width: 80%;
    }
  }  
}