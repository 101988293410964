.review-card-box {
  width: 500px;
  height: 294px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 24px;
  border: 1px solid #D5D6E1;
  flex-shrink: 0;
  position: relative;

  .review-stars {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .review-msg {
    color: #686A79;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box!important;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-line-clamp: 3;
  }

  .user-details-n-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);

    .name-n-date {
      .review-name {
        color: #202124;
        font-family: 'Syne', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .review-date {
        margin-top: 4px;
        color: #686A79;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .user-img {
      height: 48px;
      width: 48px;
      img {
        height: 48px;
        width: 48px;
        border-radius: 50%;
      }
    }
  }
}


@media screen and (max-width: 1000px) {
  .review-card-box {
    width: 460px;
    height: 264px;
    padding: 24px;
    gap: 28px;
  }
}
@media screen and (max-width: 720px) {
  .review-card-box {
    width: 300px;
    height: 290px;
    padding: 24px;
    gap: 24px;

    .review-msg {
      font-size: 16px;
      -webkit-line-clamp: 5;
      line-height: 24px;
    }

    .user-details-n-date {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .name-n-date {
        .review-name {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
}