@import "../../sass/variables";

.new-section-heading {
  line-height: 38px;
  font-family: 'Syne', sans-serif;
  font-size: 32px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  .section-heading-text {
    line-height: 38px;
    text-transform: capitalize;
  }
  .bold-section-heading {
    font-weight: 700;
  }
}

.section-heading-text.pintar-icon {
  position: relative;
}
.section-heading-text.pintar-icon::after {
  content: " ";
  height: 32px;
  width: 32px;
  margin-left: 8px;
  margin-top: 3px;
  line-height: 24px;
  position: absolute;
  background-image: url("../../assets/Icons/pintar-icon.svg");
  background-size: cover;
}

.app-download-text {
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  margin-top: 4px;
  span {
    font-weight: 600;
  }
  a {
    // text-decoration: none;
    font-weight: 600;
    color: #D5AC4F;
  }
}


.filter-btn {
  display: flex;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 16px;
width: 178px;
height: 38px;
  border-radius: 4px;
border: 1px solid #D5D6E1;
background: #FFF;

color: #292D32;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px;
cursor: pointer;
}

.browse-btn {
  display: flex;
align-items: center;
gap: 8px;
  color: #D5AC4F;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 28px;
text-transform: capitalize;
}

.with-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reviews-slide-btns {
  display: flex;
  gap: 24px;

  .review-slider-btn {
    display: flex;
    align-items: center; 
    justify-content: center;
    height: 44px;
    width: 88px;
    border-radius: 16px;
    border: 1px solid #191A23;
    background: #191A23;
    // cursor: pointer;
    transform-origin: 0.5s all;
    // svg path {
    //   stroke: #fff;
    // }
  }
  
  .review-slider-btn.disabled {
    // background: #fff;
    // cursor: auto;
    pointer-events: none;
    // svg path {
    //   stroke: #191A23;
    // }
  }
}


@media screen and (max-width: 1000px) {
  .new-section-heading {
    font-size: 24px;
    line-height: 29px;
    .section-heading-text {
      line-height: 29px;
    }
  }
  .app-download-text {
    font-size: 12px;
    line-height: 18px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .section-heading-text.pintar-icon::after {
    content: " ";
    height: 20px;
    width: 20px;
    margin-left: 6px;
    margin-top: 6px;
    line-height: 20px;
    background-image: url("../../assets/Icons/pintar-icon.svg");
  }
}

@media screen and (max-width: 900px) {
  .hide-text-heading {
    display: none;
  }
}
@media screen and (max-width: 720px) {
  .reviews-slide-btns {
    display: none;
  }
  .blog-section-heading {
    justify-content: left !important;
    .section-heading-text {
      text-align: left !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .with-filter {
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    .section-heading-text {
      text-align: center;
    }
  }
  .review-section-heading {
    .section-heading-text {
      text-align: left;
    }
  }
}