@import "../../sass/variables";

.items-section-container {
  width: 100%;
  .items-cards-section {
    display: flex;
    gap: 22px;
    margin-top: 32px;
    width: 100%;

    
  }
}


.items-cards-section {
  display: flex;
  gap: 16px;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  overflow-x: scroll;
  // padding: 2px;
  /* border: 1px solid #000; */
  /* padding: 4px 16px; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.items-cards-section::-webkit-scrollbar {
  display: none;
}


@media screen and (max-width: 1000px) {
  .items-section-container {
    .items-cards-section {
      gap: 16px;
      margin-top: 16px;
    }
  }
}