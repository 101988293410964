.home-page-key-features {
  background-image: url(../../../assets/HomePage/KeyFeatures/mask-min.png);
  background-color: rgba($color: $color-dark-yellow-1, $alpha: 1);
  padding: 0 7rem;
  background-size: cover;
  position: relative;

  .inner-container {
    padding: 2rem;

    .head-container {
      padding: 5rem 2rem 2rem 2rem;
      text-align: center;
      font: $bold-weight 3.4rem/3.8rem Poppins;
      letter-spacing: 0.034rem;
      color: $color-white;
    }
    .cards-container {
      @include flex-center;
      flex-direction: row;

      flex-wrap: wrap;
      padding: 2rem;
      width: 100%;
      max-width: $screen-xl;
      margin: auto;
      .card-container:hover {
        transform: scale(1.15);
        background: $color-dark-grey-1 0% 0% no-repeat padding-box;
      }

      .card-container {
        flex: 0 0 23%;
        border-radius: 1.5rem;
        cursor: pointer;
        background: $color-grey-light-2 0% 0% no-repeat padding-box;
        transition: 0.2s ease-in;
        min-width: 25rem;
        margin: 2rem 3.5rem 2rem 3.5rem;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);

        .card-content {
          border-radius: 1.2rem;
          height: 100%;
          margin-top: 0.6rem;
          padding: 3rem;
          background: $color-white 0% 0% no-repeat padding-box;

          .card-icon-container {
            height: 50%;
            padding: 0.5rem;

            .icon-circle {
              width: 7rem;
              height: 7rem;
              border-radius: 50%;
              @include flex-center;
              background: transparent
                linear-gradient(
                  58deg,
                  $color-dark-yellow-1 0%,
                  $color-white 100%
                )
                0% 0% no-repeat padding-box;

              .icon {
                background-size: cover;
                height: 60%;
                width: 60%;
              }
            }
          }

          .card-text {
            margin-top: 2rem;
            height: 50%;

            .card-heading {
              font: $bold-weight 1.5rem/2rem Poppins;
              color: $color-dark-grey-1;
            }

            .card-text-body {
              padding-top: 1rem;
              padding-bottom: 1rem;
              text-align: left;
              font-weight: $regular-weight;
              font-size: 1.2rem;
              color: $color-dark-grey-1;
            }
          }
        }
      }
    }
  }
}

@media(max-width:768px) {
  .home-page-key-features {
    background-image: none;
    padding: 0 2.2rem;
    .inner-container {
      .head-container {
        padding: 5rem 0rem 0rem;
      }
      .cards-container {
        .card-container {
          margin: 2rem;
        }
      }
    }
  }  
}

@media(max-width:630px) {
  .home-page-key-features {
    .card-container {
      flex: 0 0 100% !important;
      max-width: 45rem;
    .card-content {
      .card-text {
        .card-text-body {
          max-width: 22.5rem;
        }
      }
    }
  }
  }  
}

@media(max-width:400px) {
  .home-page-key-features {
    .card-container {
    .card-content {
      .card-text {
        .card-text-body {
          max-width: 20rem;
        }
      }
    }
  }
  }  
}