@import "../../sass/variables";
@import "../../sass/mixins";
@import "../../components/HomePage/Info/info.scss";
@import "../../components/HomePage/KeyFeatures/key-features.scss";
@import "../../components/HomePage/Landing/landing.scss";
@import "../../components/HomePage/Products/products.scss";
@import "../../components/HomePage/Services/services.scss";
@import "../../components/HomePage/Video/video.scss";
@import "../../components/HomePage/Download/download.scss";

.InteractiveHome {
  // padding: 0 7rem;

}

.homepage-content-container {
  display: flex;
  width: 100vw;
  justify-content: center;
  padding-bottom: 100px;
  .homepage-content {
    max-width: 1200px;
    margin: 0 24px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 100px;

    margin-top: 80px;
  }
  .homepage-content.bottom-section-homepage {
    margin-top: 100px;
  }
}

@media screen and (max-width: 1200px) {
  .homepage-content-container .homepage-content {
    margin-left: 0;
    margin-right: 0;
    padding: 0 24px;
  }
}
@media screen and (max-width: 900px) {
  .homepage-content-container .homepage-content {
    margin-left: 0;
    margin-right: 0;
    padding: 0 16px;
  }
}

@media screen and (max-width: 1000px) {
  .homepage-content-container {
    padding-bottom: 48px !important;
    .homepage-content {
      gap: 48px;
      margin-top: 38px;
    }
    .homepage-content.bottom-section-homepage {
      margin-top: 48px;
    }
  }
}