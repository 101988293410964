@import "../../sass/variables";

.categories-section {
  width: 100%;
  .categories-cards {
    display: flex;
    gap: 24px;
    margin-top: 32px;
    width: 100%;

    .categories-card-item-1, .categories-card-item-2 {
      width: calc(50% - 12px);
      background-color: red;
      aspect-ratio: 1/0.75;
      padding-left: 60px;
      padding-right: 32px;
      display: flex;
      align-items: center;
      box-sizing: border-box;

    }
    .categories-card-text {
      position: relative;
      font-family: 'Syne', sans-serif;
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      text-transform: capitalize;
      color: #191A23;
      .bold {
        font-weight: 800;
      }
      .category-nav-btn {
        width: 150px; 
        height: 40px;
        background: red;
        position: absolute;
        bottom: -56px; 
        left: 0;
        border-radius: 16px;
        background: #292D32;
        color: #fff;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        justify-content: center;
        gap: 10px;
        align-items: center;
        cursor: pointer;
      }
    }
    .categories-card-item-1 {
      border-radius: 16px;
      background: url("../../assets/images/categories-bg-1.png"), lightgray -89.61px -81.562px / 142.278% 119.196% no-repeat;
      background-size: cover;
    }
    .categories-card-item-2 {
      border-radius: 16px;
      background: url("../../assets/images/categories-bg-2.png"), gray 50% / cover no-repeat;
      background-size: cover;
    }
  }
}


@media screen and (max-width: 1120px) {
  .categories-section {
    .categories-cards {
      .categories-card-text {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .categories-section {
    .categories-cards {
      margin-top: 16px;
      .categories-card-item-1, .categories-card-item-2 {
        width: calc(50% - 8px);
      }
      gap: 16px;
      .categories-card-text {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .categories-section {
    .categories-cards {
    .categories-card-item-1, .categories-card-item-2 {
    padding-bottom: 40px;
    }
      .categories-card-text {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
@media screen and (max-width: 740px) {
  .categories-section {
    .categories-cards {
    .categories-card-item-1, .categories-card-item-2 {
      padding-right: 16px;
      padding-left: 32px;
    }
      .categories-card-text {
        font-size: 18px;
        line-height: 22px;
        .category-nav-btn {
          // width: 150px; 
          // height: 40px;
          // background: red;
          position: absolute;
          bottom: -48px; 
          left: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .categories-section {
    .categories-cards {
    .categories-card-item-1, .categories-card-item-2 {
      padding-right: 12px;
      padding-left: 24px;
    }
      .categories-card-text {
        font-size: 16px;
        line-height: 20px;
        .category-nav-btn {
          // width: 150px; 
          // height: 40px;
          // background: red;
          position: absolute;
          bottom: -48px; 
          left: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 520px) {
  .categories-section {
    .categories-cards {
    .categories-card-item-1, .categories-card-item-2 {
      padding-right: 6px;
      padding-left: 10px;
      height: 190px;
    }
    .categories-card-item-1 {
      background: url("../../assets/images/pintar-category-1-mobile.png");
      background-size: cover;
      background-position: center center;
    }
      .categories-card-text {
        font-size: 16px;
        line-height: 19px;
        .category-nav-btn {
          width: 126px; 
          // height: 40px;
          // background: red;
          position: absolute;
          bottom: -48px; 
          left: 0;
        }
      }
    }
  }
}