@import "../../sass/variables";

.sale-banner-container {
  height: 120px;
  border-radius: 24px;
  background: url("../../assets/images/sale-banner.png"), pink 50% / cover no-repeat;
  background-size: cover;
  display: flex;
  padding: 32px 24px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  font-size: 32px;
  line-height: 48px;
  color: $color-dark-grey-main;

  .bold {
    font-weight: 600;
  }
  
  .sale-text {}

  .sale-vertical-line {
    height: 100%;
    width: 1px;
    background-color: #9FA3A6;
  }

  .timer-container {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 508px;
    // padding-right: 16px;
    .timer-dots {
      color: #9FA3A6;
    }
    .timer-text {
      font-size: 20px;
      line-height: auto;
      font-weight: 400;
    }
    .digit.flex {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 700;
    }
  }
}


@media screen and (max-width: 1180px) {
  .sale-banner-container {
    font-size: 28px;
    .timer-container {
      gap: 16px;
      width: 414px;
      .timer-text {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 1028px) {
  .sale-banner-container {
    padding: 24px 16px;
    height: 100px;
    font-size: 24px;
    line-height: 28px;
    .timer-container {
      gap: 12px;
      width: 324px;
      .timer-text {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .sale-banner-container {
    font-size: 20px;
    line-height: 24px;
    height: 80px;
    border-radius: 16px;
    .timer-container {
      gap: 10px;
      width: 298px;
      .timer-text {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
@media screen and (max-width: 760px) {
  .sale-banner-container {
    font-size: 18px;
    line-height: 22px;
    height: 80px;
    border-radius: 14px;
    .timer-container {
      gap: 8px;
      width: 276px;
      .timer-text {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .sale-banner-container {
    background: url("../../assets/images/SALE\ BANNER\ MOBILE.png"), pink 50% / cover no-repeat;
    background-size: cover;
    .sale-vertical-line {
      display: none;
    }
    flex-direction: column;
    gap: 4px;
    align-items: start;
    justify-content: center;
    font-size: 14px;
    line-height: 21px;
    height: 104px;
    padding: 0 24px;
    border-radius: 14px;
    .timer-container {
      gap: 4px;
      width: 100%;
      .timer-text {
        font-size: 14px;
        line-height: 21px;
      }
      .digit {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}