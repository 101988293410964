.home-page-services {
  padding: 5.5rem 7rem;
  max-width: $screen-xl;
  margin: auto;

  @media (min-width: $screen-xxl) {
    padding: 4rem 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 5.8rem 2.2rem;
  }

  .services {
    &__heading {
      font: $bold-weight 3.4rem/5.1rem $font-name;
      letter-spacing: 0.34rem;
      color: $color-dark-grey-1;
      margin-bottom: 6.4rem;

      @media only screen and (max-width: 768px) {
        margin-bottom: 4rem;
        text-align: center;
      }
    }

    &__content {
      display: flex;

      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &__buttons {
      margin-right: 8.5rem;
      flex: 1;

      &_inside {
        @media only screen and (max-width: 768px) {
          display: flex;
          flex-direction: row-reverse;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          max-width: 33rem;
          width: 100%;
        }
        @media only screen and (max-width: 600px) {
          max-width: 33rem;
          width: 100%;
        }
      }

      @media only screen and (max-width: 768px) {
        margin-right: 0;
      }
      @include flex-center;

      &-icon--1 {
        margin-left: 1.2rem;
      }

      &-icon--2 {
        margin-right: 1.2rem;
      }
    }

    &__button {
      &:not(:last-child) {
        margin-bottom: 2.4rem;

        @media only screen and (max-width: 768px) {
          margin-bottom: 0;
        }
      }

      &_inside {
        @media only screen and (max-width: 768px) {
          width: 140px;
        }
        @media only screen and (max-width: 340px) {
          width: 120px;
        }

        @media only screen and (max-width: 300px) {
          width: 90px;
        }
      }
    }

    &__carousel {
      align-items: center;
      overflow: hidden;
      width: 90%;
      @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 4rem;
      }
    }

    &__item {
      // flex: 1;

      @media only screen and (max-width: $bp-semi-medium) {
        width: 60%;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &__image {
      width: 30rem;
      height: 20rem;
      border-radius: 3rem;
      margin-bottom: 3.2rem;

      @media only screen and (max-width: 768px) {
        width: 30rem;
        height: 20rem;
      }
    }

    &__item-heading {
      font: $bold-weight 2rem/3rem $font-name;
      color: $color-dark-grey-1;
      letter-spacing: 0.2px;
      margin-bottom: 1.6rem;
    }

    &__item-text {
      font: $regular-weight 1.4rem/2.1rem $font-name;
      letter-spacing: 0.14px;
      color: $color-dark-grey-1;
      margin-bottom: 1.6rem;
      width: 85%;
      @media only screen and (max-width: 600px) {
        max-width: 30rem;
        width: 100%;
      }
    }

    &__item-button {
      border: none;
      background: none;

      text-transform: uppercase;
      color: $color-dark-yellow-1;

      font: $bold-weight 1.4rem/2.1rem $font-name;
      letter-spacing: 0.14px;
      @include flex-center;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    &__item-coming-soon {
      text-transform: uppercase;
      color: $color-dark-yellow-1;
      font: $bold-weight 1.4rem/2.1rem $font-name;
      letter-spacing: 0.14px;
      cursor: default;
    }

    &__item-icon {
      width: 3rem;
      height: 2rem;
      fill: $color-dark-yellow-1;
      margin-left: 0.8rem;
    }
  }
}

.carousel-button-group {
  display: none;
}
