@import "../../sass/variables";

.offering-factors {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
  background: #F6F3EA;
  height: 244px;

  .offering-factor {
    width: 122px;
    .bold {
      font-weight: 700;
    }
  }
}

@media screen and (max-width: 800px) {
  .offering-factors {
    height: 200px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 20px;
    .offering-factor {
      width: 94px;
      svg {
        width: 80px;
        height: 80px;
      }
      // width: 100px;
    }
  }
}
@media screen and (max-width: 550px) {
  .offering-factors {
    height: 100px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 16px;
    .offering-factor {
      width: max-content;
      svg {
        width: 48px;
        height: 48px;
      }
      // width: 100px;
    }
    .offering-factor:nth-child(5) {
      display: none;
    }
  }
}