@import "../../sass/variables";

.blogs-section-container {
  width: 100%;
  .blogs-cards-section {
    // scroll-behavior: smooth;
    display: flex;
    gap: 24px;
    margin-top: 32px;
    width: 100%;
    height: auto;
    overflow-x: scroll;
  /* border: 1px solid #000; */
  /* padding: 4px 16px; */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  }
}
.blogs-cards-section::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1000px) {
  .blogs-section-container {
    .blogs-cards-section {
      gap: 16px;
      margin-top: 16px;
    }
  }
}