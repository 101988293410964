.home-page-products {
  background-color: rgba($color: $color-grey-light-1, $alpha: 1);
  padding: 0 7rem;
  
  @media only screen and (max-width: 768px) {
    padding: 5.8rem 2.2rem;
  }

  .inner-container {
    padding: 2rem;
    max-width: $screen-xl;
    margin: auto;
  
    
  @media(min-width: $screen-xxl){
    padding: 2rem 0;
  }
    @media only screen and (max-width: 768px) {
      padding: 0rem;
    }

    .head-container {
      padding: 4rem 2rem 2rem 2rem;
      text-align: center;
      font: $bold-weight 3.4rem/3.8rem $font-name;
      color: $color-dark-grey-1;
    }

    .cards-container {
      padding: 0rem 2rem 2rem 2rem;
      width: 90%;
      margin: auto;
      overflow: hidden;

      @media only screen and (max-width: 768px) {
        padding: 0rem;
        width: 100%;
      }

      .coursalItem {
        @include flex-center;
        flex-direction: row;
      }

      .customDotsList {
        .react-multi-carousel-dot {
          button {
            border-color: $color-dark-yellow-1;
          }
        }

        .react-multi-carousel-dot--active {
          button {
            background: $color-dark-yellow-1;
          }
        }
      }

      .card-container:hover {
        border: 0.2rem solid $color-dark-yellow-1;
      }

      .card-container {
        border: 0.2rem solid $color-grey-light-3;
        width: 27rem;
        border-radius: 3.5rem;
        padding: 1rem;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        margin: 4rem;
        min-width: 27rem;
        text-decoration: none;

        .card-content {
          height: 100%;
          border-radius: 3.3rem;
          padding: 1rem;

          .card-img {
            border-radius: 0.8rem;
            // width: 21rem;
            height: 21rem;

            .image {
              border-radius: 0.8rem;
              width: 100%;
              height: 100%;
            }
          }

          .card-details {
            margin-top: 1rem;

            .card-heading {
              font: bold 1.8rem/3.2rem $font-name;
              color: $color-dark-grey-1;
              margin-top: 1.8rem;
            }

            .card-descript {
              font: 1.4rem/2rem $font-name;
              color: $color-dark-grey-1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-top: 1rem;
            }
            .card-price-button-container {
              padding-top: 2rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              .card-price {
                font: $bold-weight 2rem/3.2rem $font-name;
                color: $color-dark-yellow-1;
              }
              .card-button {
                button {
                  display: flex;
                  border-radius: 0.5rem;
                  border: none;
                  padding: 0.5rem 0.8rem 0.5rem 0.8rem !important;
                  height: 3.5rem;
                  .a {
                    fill: none !important;
                  }
                  p {
                    align-self: center;
                    font: $bold-weight 1.2rem $font-name;
                    color: $color-dark-grey-1;
                  }
                }
              }
            }

            .card-discounts-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;
              padding-top: 0.7rem;
              min-height: 4rem;
              .card-discount-price {
                text-decoration: line-through;
                font: 1.4rem/3.4rem $font-name;
                color: $color-dark-grey-3;
              }
              .card-discount-percent {
                font: 1.4rem/3.4rem $font-name;
                color: $color-dark-grey-4;
                margin-left: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
