.home-page-landing {
  // height: 744px;
  height: auto;
  width: 100vw;
  // padding: 4rem 7rem;
  // max-width: $screen-xl;
  // margin: auto;
  .hero-section-bg {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 0;
  }
  
  @include flex-center;

  @media(min-width: $screen-xxl){
    padding: 4rem 0;
  }

  .left-side {
    padding-right: 5rem;
    .heading {
      color: $color-dark-grey-1;
      font-size: 6rem;
      font-weight: $semi-bold-weight;
      line-height: 1.1;
      max-width: 60rem;
    }
    .text {
      color: $color-dark-grey-1;
      font-size: 1.8rem;
      margin: 4rem 0;
      line-height: 1.4;
      max-width: 55rem;
    }
  }

  .right-side {
    display: flex;
    margin-left: auto;
    .right-side-first-div {
      margin-right: 1.5rem;
    }
  }
  .image-1-container {
    width: 26.4rem;
    height: 21.2rem;
    margin-bottom: 1.5rem;
  }
  .image-2-container {
    width: 26.4rem;
    height: 27rem;
  }
  .image-3-container {
    width: 26.3rem;
    height: 32.4rem;
    margin-bottom: 1.5rem;
  }
  .image-4-container {
    width: 26.4rem;
    height: 15.7rem;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
  }
}

@media(max-width:768px) {
  .home-page-landing {
    padding: 5rem 2.2rem;
    flex-direction: column-reverse;

    .left-side {
      padding-right: 0;
      .heading {
        font-size: 4rem;
        max-width: 50rem;
        text-align: center;
        margin-top: 5rem;
      }
      .text {
        font-size: 1.8rem;
        max-width: 50rem;
        text-align: center;
      }

      .button {
        width: 100%;
        max-width: 50rem;
      }
    }
  
    .right-side {
      margin-left: 0;
    }
  }   
}

@media(max-width:520px) {
  .home-page-landing {
    .image-1-container {
      width: calc(50vw - 2.2rem - 0.7rem);
      height: 12.5rem;
      margin-bottom: 1.5rem;
    }
    .image-2-container {
      width: calc(50vw - 2.2rem - 0.7rem);
      height: 18rem;
    }
    .image-3-container {
      width: calc(50vw - 2.2rem - 0.7rem);
      height: 19.5rem;
      margin-bottom: 1.5rem;
    }
    .image-4-container {
      width: calc(50vw - 2.2rem - 0.7rem);
      height: 11rem;
    }
  }
}