.blog-card-box {
  flex-shrink: 0;
  display: block;
  width: 384px;
  height: auto;
  box-sizing: border-box;
}
  
  .blog-img {
    width: 384px;
    height: auto;
    box-sizing: border-box;
    img {
      width: 384px;
      height: auto;
      border-radius: 12px;
    }
  }
  
  .blog-card-box .blog-info-container {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .blog-label {
      height: 36px;
      line-height: 36px !important;
      padding: 0 16px;
      border-radius: 16px;
      color: #231F20;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      width: max-content;
      max-width: 95%;
      text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    }

    .blog-title {
      color: #231F20;
      font-family: 'Syne', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;

    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box!important;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-line-clamp: 3;

    }

    .blog-author-read {
      color: #566A75;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 28px;
      display: flex;
      gap: 12px;
      align-items: center;

      .dot {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: #231F20;
      }
    }
  }


  @media screen and (max-width: 720px) {
    .blog-card-box {
      flex-shrink: 0;
      display: block;
      width: 260px;
      height: auto;
      box-sizing: border-box;
    }
      
    .blog-img {
      width: 260px;
      height: auto;
      box-sizing: border-box;
      img {
        width: 260px;
        height: auto;
        border-radius: 12px;
      }
    }
  }