
.download-container {
  .download-pintar-app-banner {
    margin-top: 32px;
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1000px) {
    .download-container {
        .download-pintar-app-banner {
          margin-top: 16px;
        }
      }
}

// .download-outer-container {
//   // padding: 5rem 7rem;
//   background: $color-grey-light-1;
  
//   @media(min-width:1500px) {
//     // padding: 5rem 0rem 0rem;
//   }
//   @media(max-width:768px) {
//     margin-bottom: -8px;
//   }
//   @media(min-width:768px) {
//     margin-bottom: -7px;
//   }
//   @media(min-width:1250px) {
//     margin-bottom: -9px;
//   }
// }


// .download-container {
//   .download-desktop-lg {
//     display: none;
//     position: relative;


//     .bg-container {
//       position: absolute;
//       bottom: 0;
//       width: 100%;
//       & img {
//         width: 100%;
//         max-height: 390px;
//       }
//     } 

//     .content-container {
//       padding: 5rem 7rem;
//       height: 535px;
//       position: relative;
//       display: flex;
//       max-width: 1570px;
//       margin: auto;
//     }

//     .left-side {
//       width: 618px;
//       min-width: 618px;
//     }

//     .right-side {
//       width: 100%;
//       position: relative;
//     }

//     .heading {
//       font-size: 50px;
//       font-weight: 700;
//       color:$color-dark-grey-1;
//       margin-bottom: 15px;
//       line-height: 1.5;
//     }
//     .text {
//       font-size: 20px;
//       color:$color-dark-grey-1;
//     }
//     .links-container {
//       z-index: 1;
//       position: absolute;
//       bottom: 65px;
//       display: flex;

//       & a:first-child {
//         margin-right: 30px;
//       }
//     }

//     .hand-image-container {
//       position: absolute;
//       right: 0;
//       bottom: 0;
//       z-index: 1;
//       & img {
//         width: unset;
//         // max-width: 540px;
//         max-height: 470px;
//       }
//     }
//   }
//   .download-desktop {
//     display: block;

//     .bg-container {
//       position: relative;
//       width: 100%;
//       max-width: 1400px;
//       margin: auto;

//       img {
//         width: 100%;
//       }
//     }

//     .links-outer-container {
//       max-width: 1400px;
//       margin: auto;
//       position: relative;
//     }
//     .links-container {
//       position: absolute;
//       display: flex;
//       bottom: 4.5vw;
//       left: 6rem;
//       @media (min-width:1400px) {
//         bottom: 60px;
//       }

//       & img {
//         height: 5vw;
//         max-height: 60px;
//         @media (min-width:1400px) {
//           height: 70px;
//           max-height: unset;
//         }
//       }
//       & img:first-child{
//         margin-right: 20px;
//       }
//     }
//   }
//   .download-mobile {
//     display: none;
//     position: relative;


//     .content-container {
//       padding: 4rem 9.2rem 0;
//       margin: auto;
//       max-height: 395px;

//       @media (max-width:600px) {
//         padding: 4rem 2.2rem 0;
//       }
//     }
    
//     .heading {
//       font-size: 2.4rem;
//       font-weight: 700;
//       color:$color-dark-grey-1;
//       text-align: center;
//       margin-bottom: 15px;
//       line-height: 1.5;
//     }
//     .text {
//       font-size: 1.4rem;
//       color:$color-dark-grey-1;
//       text-align: center;
//     }
//     .links-container {
//       display: flex;
//       justify-content: space-between;
//       margin: 20px 0;

//       & a:first-child {
//         margin-right: 30px;
//       }
//       & a {
//         width: calc(50% - 15px);

//         & img {
//           width: 100%;
//         }
//       }

//     }
//     .bg-container {
//       width: 100%;
//       & img {
//         width: 100%;
//       }
//     }
//   }
  
//   @media(min-width:1400px) {
//     .download-desktop {
//       display: none;
//     }
//     .download-desktop-lg {
//       display: block;
//     }
//   }
//   @media(max-width:768px) {
//     .download-desktop {
//       display: none;
//     }
//     .download-mobile {
//       display: block;
//     }
//   }
// }