.home-page-video {
  height: auto;
  display: flex;
  gap: 64px;

  .video-container {
      line-height: 0;
      width: 52%;
      display: flex;
      align-items: center;
      aspect-ratio: 1/0.58;

      .video-wrapper {
        width: 100%;
        aspect-ratio: 1/0.58;
        background-color: #000;
        border-radius: 24px;;
      }
      .yt-cover {
        width: 100%;
        aspect-ratio: 1/0.58;
        background-image: url("../../../assets/images/yt-cover.png");
        background-size: cover;
        border-radius: 24px;
        cursor: pointer;
      }
      iframe {
        width: 100%;
        aspect-ratio: 1/0.58;
        border-radius: 24px;
      }
    }

    .intro-text-container {
      width: calc(48% - 64px);
      padding: 26PX 0;
      color: #191A23;
      box-sizing: border-box;
      padding-right: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // height: 100%;

      .intro-text-heading {
        font-family: 'Syne', sans-serif;
        font-family: Syne;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 58px;
      }
      .intro-text-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
      }
    }

    .video-sec-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 32px;
      border-radius: 16px;
      background: #292D32;
      height: 54px;
      width: max-content;
      color: #fff;
      font-size: 14px;
      line-height: 22px;
      margin-top: 32px;
      cursor: pointer;
    }

  }


  @media screen and (max-width: 1000px) {
    .home-page-video {
      gap: 48px;
      .intro-text-container {
        .intro-text-heading {
          font-size: 36px;
          line-height: 42px;
        }
        .intro-text-desc {
          font-size: 14px;
          line-height: 22px;
          margin-top: 8px;
        }
      }
      .video-sec-btn {
        margin-top: 24px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .home-page-video {
      flex-direction: column;
      gap: 16px;
      .video-container {
        width: 100%;
      }
      .intro-text-container {
        width: 100%;
        padding: 0;
        .intro-text-heading {
          font-size: 24px;
          line-height: 29px;
        }
        .intro-text-desc {
          font-size: 16px;
          line-height: 24px;
          margin-top: 12px;
        }
      }
      .video-sec-btn {
        margin-top: 24px;
      }
    }
  }