/* carousel */
.carousel{
    position: relative;
    width: 100vw;
    aspect-ratio: 1/0.4278;
    background-color: #e9e8eb;
   //  aspect-ratio: 1/0.515;
 }
 
 .carousel_inner{
    width: 100%;
    height: auto;
 }
 
 .carousel_inner::after{
    content: "";
    display: block;
    clear: both;
 }
 
 .carousel_item{
    position: relative;
    float: left;
   //  display: none;
    width: 100%;
    height: auto;
    margin-right: -100%;
    opacity: 0;
   transition: opacity ease-in-out 0.8s;
 }
 
 /* slide effect */
 .carousel_item__active,
 .carousel_item__pos_prev,
 .carousel_item__pos_next{
   //  display: block;
   opacity: 1;
 }
 
 .carousel_item__pos_prev{
    left: -100%;
 }
 
 .carousel_item__pos_next{
    left: 100%;
 }
 
 .carousel_item__prev{
    transform: translateX(100%);
    transition: transform .5s ease-in-out;
 }
 
 .carousel_item__next{
    transform: translateX(-100%);
    transition: transform .5s ease-in-out;
 }
 
 /* fade effect */
 .carousel__fade .carousel_item__pos_prev,
 .carousel__fade .carousel_item__pos_next{
    left: 0;
    opacity: 0;
 }
 
 .carousel__fade .carousel_item__prev,
 .carousel__fade .carousel_item__next{
    transform: none;
    opacity: 1;
    transition: opacity .5s ease-in-out;
 }
 
 .carousel__fade .carousel_item__active.carousel_item__prev,
 .carousel__fade .carousel_item__active.carousel_item__next{
    opacity: 0;
 }
 
 /* carousel */
 .carousel_img{
    display: block;
    width: 100%;
    height: auto;
 }
 
 .carousel_caption{
    position: absolute;
    bottom: 30%;
    left: 5%;
    z-index: 1;
    line-height: 0;
    width: max-content;
    height: max-content;
 }
 
 .carousel_title{
    font-family: 'Syne', sans-serif;
font-size: 64px;
font-style: normal;
font-weight: 600;
line-height: 76px;
 }
 
 .carousel_description{
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 30px;
 }

 .carousel_shop_now_btn {
    border-radius: 16px;
background: #292D32;
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
width: 178px;
height: 56px;
display:flex;
align-items: center;
justify-content: center;
cursor: pointer;
 }
 
 .carousel_indicator{
   position: absolute;
    bottom: 48px;
    left: 5%;
    width: auto;
    padding: 0 4px;
    height: 16px;
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.30);
   //  transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 1;
 }
 
 .carousel_dot{
    display: block;
    box-sizing: border-box;
    height: 8px;
    width: 8px;
    background-color: #292D32;
    border-radius: 50%;
    padding: 0;
    border: none;
    cursor: pointer;
    transition: background-color .8s ease-in-out;
 }
 
 .carousel_dot__active{
    background-color: #FFFFFF;
    cursor: default;
    pointer-events: none;
 }
 
 .carousel_control{
    position: absolute;
    right: 5%;
    bottom: 32px;
    display: flex;
    flex-direction: row;
    width: max-content;
    height: auto;
    gap: 24px;
 }
 
 .carousel_button{
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid #191A23;
    height: 48px;
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
 }


 @media screen and (max-width: 1060px) {
   .carousel_title {
      font-size: 56px;
      line-height: 64px;
   }
   .carousel_caption {
      bottom: 28%;
   }
   .carousel_shop_now_btn {
      width: 156px;
    height: 42px;
   }
   .carousel_description {
      font-size: 18px;
   }
   .carousel_indicator {
      bottom: 40px;
      // left: 70px;
   }
   .carousel_control {
      bottom: 32px;
      // right: 70px;
   }
 }
 @media screen and (max-width: 900px) {
   .carousel_title {
      font-size: 40px;
      line-height: 48px;
   }
   // .carousel_caption {
   //    gap: 16px;
   // }
   .carousel_description {
      font-size: 16px;
      line-height: 24px;
   }
   .carousel_indicator {
      bottom: 40px;
   }
   .carousel_control {
      display: none;
   }
 }
 @media screen and (max-width: 760px) {
   .carousel_title {
      font-size: 32px;
      line-height: 38px;
   }
   .carousel_description {
      font-size: 14px;
      line-height: 20px;
   }
   .carousel_indicator {
      bottom: 24px;
   }
   .carousel_control {
      display: none;
   }
   // .carousel_shop_now_btn {
   //    width: 156px;
   //  height: 42px;
   //  font-size: 14px;
   //  position: absolute;
   //  bottom: 90px;
   //  left: 5%;
   // }
 }

 @media screen and (max-width: 760px) {
   .carousel_title {
      font-size: 32px;
      line-height: 38px;
   }
   .carousel_caption {
      bottom: 26%;
   }
   .carousel_description {
      font-size: 14px;
      line-height: 20px;
   }
   // .carousel_indicator {
   //    bottom: 24px;
   //    left: auto;
   //    right: 40px;
   // }
   .carousel_control {
      display: none;
   }
   .carousel_shop_now_btn {
      width: 142px;
    height: 36px;
    border-radius: 15px;
    font-size: 14px;
   }
 }

 @media screen and (max-width: 560px) {
   .carousel_title {
      font-size: 28px;
      line-height: 34px;
   }
   .carousel_caption {
      left: 6%;
      top: 44%;
   }
   .carousel_description {
      font-size: 12px;
      line-height: 18px;
   }
   // .carousel_indicator {
   //    bottom: 24px;
   //    left: auto;
   //    right: 40px;
   // }
   .carousel_control {
      display: none;
   }
   .carousel_shop_now_btn {
      width: 128px;
   //  height: 28px;
   //  font-size: 14px;
   }
 }

 @media screen and (max-width: 500px) {
   .carousel{
      position: relative;
      width: 100vw;
      aspect-ratio: 0.7469/1;
      height: auto;
   }
   .carousel_title {
      font-size: 40px;
      line-height: 48px;
   }
   // .carousel_caption {
   //    gap: 24px;
   //    left: 16px;
   //    width: calc(100% - 32px);
   // }
   .carousel_description {
      font-size: 16px;
      line-height: 24px;
   }
   .carousel_indicator {
      bottom: 40px;
      left: 6%;
      right: auto;
   }
   .carousel_control {
      display: none;
   }
   .carousel_shop_now_btn {
      width: 176px;
    height: 56px;
    font-size: 16px;
   }
 }