.filter-card-box {
    flex-shrink: 0;
    display: block;
    height: 362px;
    width: 282px;
    /* height: 416px;
    width: 284px; */
    /* margin: 5px 10px; */
    /* background: linear-gradient(rgb(110, 104, 104), rgb(218, 218, 214)); */
    border: 1px solid rgba(223, 228, 216, 0.90);
    box-sizing: border-box;

    border-radius: 142px 142px 16px 16px;
    -webkit-border-radius: 142px 142px 16px 16px;
    -moz-border-radius: 142px 142px 16px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .filter-card-box .img-container {
    width: 280px;
    height: 280px;
    border-radius: 16px 16px 0px 0px;
    -webkit-border-radius: 16px 16px 0px 0px;
    -moz-border-radius: 16px 16px 0px 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    position: relative;
    border-radius: 142px 142px 0px 0px;
    -webkit-border-radius: 142px 142px 0px 0px;
    -moz-border-radius: 142px 142px 0px 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-bottom: 1px solid #D5D6E1;
  }
  .filter-card-box .img-container img {
    width: 100%;
    height: 100%;
    border-radius: 16px 16px 0px 0px;
    -webkit-border-radius: 16px 16px 0px 0px;
    -moz-border-radius: 16px 16px 0px 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .filter-card-box .info-container {
    width: 100%;
    height: 80px;
    border-radius: 0 0 16px 16px;
    -webkit-border-radius: 0 0 16px 16px;
    -moz-border-radius: 0 0 16px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    padding: 16px;
    background: #FAFAFA;
  }
  .filter-card-box .filter-product-title {
    color: #191A23;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
    line-height: 24px;
    width: 100%;
  }
  
  .filter-card-box .filter-view-item {
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
a {
  color: #D5AC4F;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
text-transform: capitalize;
text-align: center;
  text-decoration: none;

  display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
}
  }
  

  @media screen and (max-width: 1000px) {
    .filter-card-box {
      height: 340px;
      width: 260px;
    }
    .filter-card-box .img-container {
      height: 260px;
      width: 260px;
    }
  } 